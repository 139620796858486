import React from "react"
import { graphql } from "gatsby"
import { GlobalStyle, theme } from "../utils/theme"
import Navigation from "../sections/navigation"
import FooterSection from "../sections/footerSection"
import { StyledHeader } from "../Components/styled-components/index.styledComponents"
import StoreProvider from "../store/StoreProvider"
import { StructuredText } from "react-datocms"
import { ThemeProvider } from "styled-components"

const Article = ({ data: { article } }) => {
  return (
    <>
      <GlobalStyle />
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <div className="page-wrapper">
            <Navigation />
            <main className="article">
              <article
                className="article-header"
                style={{ backgroundImage: `url('${article.thumbnail.url}')` }}
              >
                <StyledHeader alignCenter className="outer">
                  {article.title}
                </StyledHeader>
              </article>
              <article className="article-container">
                <StructuredText
                  data={article.body}
                  renderInlineRecord={({ record }) => (
                    <a href={record.slug ? record.slug : ""}>{record.title}</a>
                  )}
                />
              </article>
            </main>
            <FooterSection />
          </div>
        </ThemeProvider>
      </StoreProvider>
    </>
  )
}

export default Article

export const query = graphql`
  query fetchArticle($slug: String) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      title
      slug
      body {
        value
        links {
          __typename
          ... on DatoCmsArticle {
            id: originalId
            title
            slug
          }
        }
      }
      thumbnail {
        url
      }
    }
  }
`
